/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

/*calendar-icon*/

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #0F4DA8;
  --main-color-transparent: rgb(15, 77, 168, 0.4);
  --second-color: #00A383;
  --third-color: #ffa100;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: rgba(15, 77, 168, 0.1);
  --neutral-color: #fff;
  --bg-selected: rgba(15, 77, 168, 0.2);

  --buero-color: rgba(15, 77, 168, 0.6);
  --homeoffice-color: rgba(0, 163, 131, 0.6);
  --termin-color: rgba(255, 161, 0, 0.6);
  --academy-color: rgba(102, 102, 102, 0.6);
  --krank-color: rgba(0, 0, 0, 0.6);
  --frei-color: rgba(255, 255, 255, 0.6);
  --urlaub-color: linear-gradient(rgba(100, 100, 100, 0.35), rgba(100, 100, 100, 0.35));
}


/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
}

.active {
  color: var(--third-color);
  font-size: 1.15em;
  font-weight: 370;
  line-height: 1.6;
}

.nav-header {
  color: white;
  text-decoration: none;
}

.nav-header:hover {
  color: white;
  text-decoration: none;
  font-size: 1.15em;
  font-weight: bold;
  line-height: 1.6;
}

.nav-header.active {
  color: var(--third-color);
  text-decoration: none;
}

.nav-header.active:hover{
  color:white;
  font-size: 1.15em;
  font-weight: bold;
  line-height: 1.6;
}

.nav-3.active:hover{
  color:white !important;
}

.nav-button span a.active:hover{
  color:white !important;
  font-size: 1.15em;
  font-weight: bold;
  line-height: 1.6;
}
.form-label {
  color: var(--text-color);
}
header {
  display: block;
  width: 100%;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

.ContentMain {
  margin-bottom: calc(30% - 60px);

}

footer {
  display: block;
  width: 100%;
  padding: 1.75em 0;
}

footer #logo-footer {
  font-size: 95%;
  text-align: center;
  color: var(--main-color);
  line-height: 2;
}

footer #logo-footer .icon-footer {
  padding-right: .35em;
}

main {
  display: block;
  margin: auto;
  margin-top: 5em;
  /* margin-left: calc(100%/7); */
  margin-bottom: 4em;
  max-width: 81em;
}

@media only screen and (max-width: 1455px) {
  main {
    margin-left: calc(100%/12);
    margin-right: 1em;
    max-width: 74em;
  }
}

@media only screen and (max-width: 870px) {
  main {
    margin-left: calc(100%/9);
    margin-right: 0.5em;
    max-width: 74em;
  }
}


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding-top: 0.1em;
  padding-right: 0.3em;
  padding-bottom: 0.1em;
  padding-left: 0.3em;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

@media only screen and (min-height: 1051px) {
  .calendar .body .cell {
    position: relative;
    height: 4.75em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
}

@media only screen and (max-height: 1050px) {
  .calendar .body .cell {
    position: relative;
    height: 3.5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
  }
}



.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  background: var(--bg-selected);
  /*border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #0F4DA8 20%,#00A383 40%);
  border-image-slice: 1;*/
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/8);
  width: calc(100%/8);
}

.btn-color {
  background-color: var(--main-color);
  border-color: transparent;
  color: var(--neutral-color);
}

.btn-color:hover {
  background-color: var(--second-color);
  border-color: transparent;
  color: var(--neutral-color);
}

.bg-acsplus {
  background-color: var(--main-color-transparent);
  border-color: transparent;
  color: var(--neutral-color);
}

/*spacing*/

.spacer-15 {
	height: 15px;
	width: 100%;
}

.spacer-30 {
	height: 30px;
	width: 100%;
}

.spacer-45 {
	height: 45px;
	width: 100%;
}

.spacer-60 {
	height: 60px;
	width: 100%;
}

.spacer-75 {
	height: 75px;
	width: 100%;
}

.spacer-90 {
	height: 90px;
	width: 100%;
}

.nav-3 {
    margin-right: 1.75em;
    margin-top: 0.25em;
    margin-bottom: 0.45em;
}

.Büro {
  background-color: var(--buero-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Homeoffice {
  background-color: var(--homeoffice-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Mobile-Office {
  background-color: var(--homeoffice-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Termin {
  background-color: var(--termin-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Urlaub {
  background-image: var(--urlaub-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Krank {
  background-color: var(--krank-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Academy {
  background-color: var(--academy-color);
  color: var(--neutral-color);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.Frei {
  background-color: var(--frei-color);
  color: var(--text-color-light);
  padding: 0 0.6em;
  width: 8em;
  height: 4em;
}

.hidden {
  display: none;
}

.flexbox-container {
  display: flex;
  flex-direction: row;
}
